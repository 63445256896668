import MuiInputLabel from '@mui/material/InputLabel'
import PropTypes from 'prop-types'

export const override = {
  styleOverrides: {
    root: {
      overflow: 'visible',
    },
  },
}

const InputLabel = ({children, ...props}) => (
  <MuiInputLabel variant="outlined" {...props}>
    {children}
  </MuiInputLabel>
)

InputLabel.propTypes = {
  children: PropTypes.node,
}

export default InputLabel
