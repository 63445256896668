import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import PropTypes from 'prop-types'
import * as resources from '../../../../../constants/resources'
import {useOneResource, useUpdateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import Button from '../../containers/Button/Button'
import {Link, Modal} from '../../visual'
import RolesForm from './RolesForm'

export const queryDef = ({roleId}) => ({
  app: '',
  resource: resources.ROLES,
  id: roleId,
  config: {
    suspense: false,
  },
})

const RolesEdit = ({refetch, setRoleId, roleId}) => {
  const [role] = useOneResource(queryDef({roleId}))
  const updateRole = useUpdateResource(null, resources.ROLES, roleId)
  const showAlert = useAlert()

  const handleClose = () => {
    setRoleId(null)
  }

  const handleSubmit = async (values) => {
    await updateRole.mutateAsync(values)
    showAlert(`Rola ${roleId} uložená`, 'success')
    if (refetch) refetch()
    handleClose()
    // Still need to handle errors somehow
  }

  if (!role) return null

  return (
    <RolesForm onSubmit={handleSubmit} initialValues={role}>
      {({submitting, form, handleSubmit}) => (
        <Modal open onClose={handleClose}>
          <Modal.Title>Rola {roleId}</Modal.Title>
          <Modal.Content>{form}</Modal.Content>
          <Modal.Actions>
            <Button icon={<CloseIcon />} onClick={handleClose}>
              Zavrieť
            </Button>
            <Button
              icon={<SaveIcon />}
              variant="contained"
              disabled={submitting}
              onClick={handleSubmit}
            >
              Uložiť
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </RolesForm>
  )
}

RolesEdit.propTypes = {
  refetch: PropTypes.func,
  setRoleId: PropTypes.func.isRequired,
  roleId: PropTypes.string.isRequired,
}

export const RolesCell = ({setRoleId, row, value}) => {
  if (row.original.role.deletedAt) return value

  const handleClick = (e) => {
    e.stopPropagation()
    setRoleId(row.original.roleId)
  }
  return <Link onClick={handleClick}>{value}</Link>
}

RolesCell.propTypes = {
  setRoleId: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  value: PropTypes.node,
}

export default RolesEdit
