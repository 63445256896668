import PropTypes from 'prop-types'
import {useEffect, useRef, useState} from 'react'
import useIsMounted from '../../srv/src/frontend/hooks/useIsMounted'

const AutoSave = ({children, debounce, save, values}) => {
  const [submitting, setSubmitting] = useState(false)
  const promise = useRef(null)
  const isMounted = useIsMounted()

  useEffect(() => {
    const handleSave = async () => {
      // Past the debounce period but waiting for previous save first
      if (promise.current) {
        await promise.current
      }

      if (isMounted.current) setSubmitting(true)
      promise.current = save(values)
      await promise.current
      promise.current = null
      if (isMounted.current) setSubmitting(false)
    }

    if (!isMounted.current) return
    const timeout = window.setTimeout(handleSave, debounce)

    return () => {
      window.clearTimeout(timeout)
    }
  }, [values, debounce]) // eslint-disable-line react-hooks/exhaustive-deps

  if (submitting) return children
  return null
}

AutoSave.propTypes = {
  children: PropTypes.node,
  debounce: PropTypes.number.isRequired,
  save: PropTypes.func,
  values: PropTypes.object,
}

export default AutoSave
