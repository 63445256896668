import CloseIcon from '@mui/icons-material/Close'
import CreateIcon from '@mui/icons-material/Create'
import SaveIcon from '@mui/icons-material/Save'
import {If} from 'babel-plugin-jsx-control-statements'
import PropTypes from 'prop-types'
import {useState} from 'react'
import * as resources from '../../../../../constants/resources'
import {useCreateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import Button from '../../containers/Button/Button'
import {Modal} from '../../visual'
import RolesForm from './RolesForm'

const RolesCreate = ({refetch}) => {
  const createRole = useCreateResource(null, resources.ROLES)
  const [open, setOpen] = useState(false)
  const showAlert = useAlert()

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async (values) => {
    const {id} = await createRole.mutateAsync(values)
    showAlert(`Rola ${id} vytvorená`, 'success')
    if (refetch) refetch()
    handleClose()
    // Still need to handle errors somehow
  }

  return (
    <>
      <Button
        icon={<CreateIcon />}
        onClick={handleOpen}
        variant="contained"
        data-test-id="createButton"
      >
        Vytvoriť
      </Button>
      <If condition={open}>
        <RolesForm onSubmit={handleSubmit}>
          {({submitting, form, handleSubmit}) => (
            <Modal open onClose={handleClose}>
              <Modal.Title>Vytvoriť rolu</Modal.Title>
              <Modal.Content>{form}</Modal.Content>
              <Modal.Actions>
                <Button icon={<CloseIcon />} onClick={handleClose}>
                  Zavrieť
                </Button>
                <Button
                  icon={<SaveIcon />}
                  variant="contained"
                  disabled={submitting}
                  onClick={handleSubmit}
                >
                  Uložiť
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </RolesForm>
      </If>
    </>
  )
}

RolesCreate.propTypes = {
  refetch: PropTypes.func,
}

export default RolesCreate
