import MuiFormControlLabel from '@mui/material/FormControlLabel'
import PropTypes from 'prop-types'

export const override = {
  // Empty
}

const FormControlLabel = ({...props}) => <MuiFormControlLabel {...props} />

FormControlLabel.propTypes = {
  control: PropTypes.node.isRequired,
  label: PropTypes.string,
}

export default FormControlLabel
