import invariant from 'invariant'
import {isEmpty, keyBy, map} from 'lodash'
import PropTypes from 'prop-types'
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import * as apps from '../../../constants/apps'
import * as componentTypes from '../../../constants/megaseal/megasealComponentTypes'
import * as resources from '../../../constants/resources'
import {generateResourcePath} from '../../../constants/routes'
import {getConsumptionLength} from '../../common/apps/megaseal/blanks'
import {
  MEGASEAL_WASTE_DIFFERENCE_HARD,
  MEGASEAL_WASTE_DIFFERENCE_FLOPPY,
} from '../../common/apps/megaseal/systemSettings'
import {getSystemSetting} from '../../common/systemSettings'
import {api} from '../utils/api'
import {readSession, useSession} from './auth'

const queryFn = async ({queryKey}) => {
  const session = readSession()
  const [method, route, options] = queryKey
  return api(method, route, {...options, sessionToken: session?.token})
}

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      suspense: true,
      queryFn,
      useErrorBoundary: true,
      staleTime: 1000 * 60 * 2, // 2 minutes
      cacheTime: 1000 * 60 * 5, // 5 minutes
    },
  },
})

export const ApiProvider = ({children}) => {
  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

ApiProvider.propTypes = {
  children: PropTypes.node,
}

// Queries
export const useOneResource = ({
  app,
  resource,
  id,
  expand,
  config = {},
  ...rest
}) => {
  invariant(isEmpty(rest), 'Unsupported option')
  const route = generateResourcePath(app, resource)
  const query = {
    filter: {
      id,
    },
    page: 0,
    pageSize: 1,
    expand,
  }
  const options = {
    query,
  }

  const {data, refetch, isFetching} = useQuery({
    queryKey: ['GET', route, options],
    retry: 3,
    ...config,
  })

  return [data?.data?.[0], {refetch, isFetching}]
}

export const useListResource = ({
  app,
  resource,
  query = {},
  config = {},
  ...rest
}) => {
  invariant(isEmpty(rest), 'Unsupported option')
  const route = generateResourcePath(app, resource)
  const options = {
    query,
  }

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['GET', route, options],
    keepPreviousData: true,
    retry: 3,
    ...config,
  })
  const {data, total} = response || {}
  return {data, total, refetch, isFetching}
}

// Mutations
export const useCreateResource = (
  app,
  resource,
  {config = {}, unwrapData = false} = {},
) => {
  const session = useSession()
  const queryClient = useQueryClient()
  const route = generateResourcePath(app, resource)
  const options = {
    sessionToken: session.token,
  }
  const mutation = useMutation(
    (data) => {
      return api('POST', route, {
        ...options,
        ...(unwrapData ? data : {data}),
      })
    },
    {
      mutationKey: `POST ${route}`,
      useErrorBoundary: true,
      onSuccess: () =>
        queryClient.invalidateQueries(['GET', route], {
          refetchInactive: true,
        }),
      ...config,
    },
  )
  return mutation
}

export const useUpdateResource = (app, resource, id) => {
  const session = useSession()
  const queryClient = useQueryClient()
  const route = generateResourcePath(app, resource, id ? id : null)
  const options = {
    sessionToken: session.token,
  }
  const mutation = useMutation(
    ({resourceId, ...data}) => {
      const params = resourceId && {resourceId}
      return api('PUT', route, {...options, data, params})
    },
    {
      mutationKey: `PUT ${route}`,
      useErrorBoundary: true,
      onSuccess: () => {
        return queryClient.invalidateQueries({
          queryKey: ['GET', generateResourcePath(app, resource)],
          refetchInactive: true,
        })
      },
    },
  )
  return mutation
}

export const useDeleteResource = (app, resource) => {
  const session = useSession()
  const queryClient = useQueryClient()
  const route = generateResourcePath(app, resource, ':resourceId')
  const options = {
    sessionToken: session.token,
  }
  const mutation = useMutation(
    (id) => {
      const params = {
        resourceId: id,
      }
      return api('DELETE', route, {...options, params})
    },
    {
      mutationKey: `DELETE ${route}`,
      useErrorBoundary: true,
      onSuccess: () => {
        return queryClient.invalidateQueries({
          queryKey: ['GET', generateResourcePath(app, resource)],
          refetchInactive: true,
        })
      },
    },
  )
  return mutation
}

export const useDeleteManyResources = (app, resource) => {
  const deleteResource = useDeleteResource(app, resource)

  const mutateAsync = async (ids) => {
    await Promise.all(map(ids, (id) => deleteResource.mutateAsync(id)))
  }
  return {...deleteResource, mutateAsync}
}

export const useSystemSettings = () => {
  const query = {
    resource: resources.SYSTEM_SETTINGS,
    query: {
      page: 0,
      pageSize: 100,
    },
    config: {
      staleTime: Infinity,
    },
  }
  const response = useListResource(query)
  const {data: systemSettings} = response || {}
  return systemSettings
}

export const useCheckBlankQuantity = (calculation) => {
  const systemSettings = useSystemSettings()
  const session = useSession()

  const checkQuantity = async () => {
    const blankIds = calculation?.componentsBlanks.map(
      (componentsBlank) => componentsBlank.blankId,
    )

    const {data: blanks} = await api(
      'GET',
      generateResourcePath(apps.MEGASEAL, resources.MEGASEAL.BLANKS),
      {
        query: {filter: {id: blankIds, deleted: false}},
        sessionToken: session?.token,
      },
    )

    const wasteDifference = {
      [componentTypes.HARD]: getSystemSetting(
        systemSettings,
        MEGASEAL_WASTE_DIFFERENCE_HARD,
      ),
      [componentTypes.FLOPPY]: getSystemSetting(
        systemSettings,
        MEGASEAL_WASTE_DIFFERENCE_FLOPPY,
      ),
    }

    const blanksById = blanks && keyBy(blanks, 'id')

    const isQuantityGreater = (componentBlank, wasteDifference) => {
      const component = calculation.seal?.components.find(
        (component) => component.id === componentBlank.componentId,
      )
      const values = {
        dimensions: calculation.dimensions,
        quantity: calculation.quantity,
      }
      const consumption =
        (component &&
          getConsumptionLength(component, values, wasteDifference)) ||
        0
      const quantity = blanksById?.[componentBlank.blankId]?.quantity || 0

      return consumption <= quantity
    }

    const isAllQuantitiesGreater = calculation?.componentsBlanks.every(
      (componentBlank) => isQuantityGreater(componentBlank, wasteDifference),
    )

    return isAllQuantitiesGreater
  }

  return checkQuantity
}
