import MuiToolbar from '@mui/material/Toolbar'
import PropTypes from 'prop-types'

export const override = {
  styleOverrides: {
    root: {
      minHeight: '57px',
    },
  },
}

const Toolbar = ({children, ...props}) => (
  <MuiToolbar {...props}>{children}</MuiToolbar>
)

Toolbar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Toolbar
