import MuiCheckbox from '@mui/material/Checkbox'
import PropTypes from 'prop-types'

export const override = {
  // Empty
}

const Checkbox = ({checked, ...props}) => (
  <MuiCheckbox color="primary" checked={checked} value={checked} {...props} />
)

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
}

export default Checkbox
