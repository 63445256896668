/* eslint-disable no-process-env */
import {toInteger} from 'lodash'
import parseDuration from 'parse-duration'

const env = (key, defaultValue) => {
  if (process.env[key] !== undefined) {
    return process.env[key]
  } else if (defaultValue !== undefined) {
    return defaultValue
  } else {
    throw new Error(`Undefined environment variable: ${key}`)
  }
}

const bool = (key, defaultValue = 'false') => {
  const value = env(key, defaultValue.toString())
  if (value === 'true') {
    return true
  } else if (value === 'false') {
    return false
  } else {
    throw new Error(`Environment variable "${key}" has invalid value: ${value}`)
  }
}

const int = (key, defaultValue) => {
  const value = env(key, defaultValue?.toString())
  if (toInteger(value).toString() === value) {
    return toInteger(value)
  } else {
    throw new Error(`Environment variable "${key}" has invalid value: ${value}`)
  }
}

const duration = (key, defaultValue) => {
  const value = env(key, defaultValue)
  const parsed = parseDuration(value)
  if (parsed !== null) {
    return parsed
  } else {
    throw new Error(`Environment variable "${key}" has invalid value: ${value}`)
  }
}

const devel = bool('devel')
const test = bool('test')
const host = env('host', '0.0.0.0')
const port = int('port', 5000)

export default {
  devel,
  test,
  sessionTimeout: duration('session_timeout', '24 hours'),
  sessionProlong: bool('session_prolong', true),
  server: {
    host,
    port,
    serveStatic: bool('serve_static', !devel),
    serveSwagger: bool('serve_swagger', devel),
  },
  db: {
    knex: {
      client: 'postgresql',
      connection: env('db_connection'),
      pool: {
        min: int('db_pool_min', 0),
        max: int('db_pool_max', 10),
      },
    },
    debug: bool('db_debug'),
    debugThreshold: duration('db_debug_threshold', '0ms'),
  },
  api: {
    url: env('api_url', `http://${host}:${port}`),
    csvDelimiter: env('csv_delimiter', ';'),
    maxLimit: int('api_max_limit', 10000),
    maxFileSize: int('api_max_file_size', 5000000),
    maxFileCount: int('api_max_file_count', 100),
    megaseal: {
      manufactureDefaultDuration: duration(
        'megaseal_manufacture_default_duration',
        '30 minutes',
      ),
      manufactureMinimalDuration: duration(
        'megaseal_manufacture_minimal_duration',
        '15 minutes',
      ),
    },
    megashop: {
      loginFailedDuration: duration(
        'megashop_login_failed_duration',
        '1 second',
      ),
      catalogPublicUrl: env('megashop_catalog_public_url', ''),
      passwordMinLength: int('megashop_password_min_length', 8),
    },
  },
  catalog: {
    api: {
      url: env('catalog_api_url', ''),
    },
  },
  smtp: bool('smtp') && {
    host: env('smtp_host'),
    port: int('smtp_port'),
    secure: bool('smtp_secure'),
    username: env('smtp_username'),
    password: env('smtp_password'),
    sender: env('smtp_sender'),
    skipCertificateCheck: bool('smtp_skip_certificate_check'),
  },
  aws: bool('aws') && {
    accountId: env('aws_account_id'),
    accessKeyId: env('aws_access_key_id'),
    secretAccessKey: env('aws_secret_access_key'),
    privateBucket: env('aws_private_bucket'),
    publicBucket: env('aws_public_bucket'),
    publicUrl: env('aws_public_url'),
    signedUrlExpires: duration('aws_signed_url_expires', '5 minutes'),
  },
  frontendDebug: bool('frontend_debug', devel),
  changelog: env('changelog', ''),
  tinymceApiKey: env('tinymce_api_key', ''),
  openai: bool('openai') && {
    apiKey: env('openai_key'),
  },
}
