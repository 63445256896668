import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import Typography from '../mui/Typography'

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'start',
    },
  },
  title: {
    whiteSpace: 'nowrap',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  screenHeaderItems: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',

    '& >:not(:last-child)': {
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(0),
        marginBottom: theme.spacing(1),
      },
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(0),
    },
  },
}))

const ScreenHeader = ({title, children, ...props}) => {
  const classes = useClasses()

  return (
    <div className={classes.root} {...props}>
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.screenHeaderItems}>{children}</div>
    </div>
  )
}

ScreenHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default ScreenHeader
