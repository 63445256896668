/**
 * Value added to the profile size before suggesting a blank for component
 * @type {number}
 */
export const BLANK_SELECTION_DIFFERENCE = 2

/**
 * Maps NH to OR sizes
 * @type {{number: number}}
 */
export const SEAL_WIDTH_TO_OR = {
  2.2: 1.78,
  3.2: 2.62,
  4.2: 3.53,
  6.3: 5.33,
  8.1: 6.99,
  9.5: 8.4,
}
