import {compact} from 'lodash'
import * as MEGASHOP from './megashop/megashopRoutes'

// Backend routes
export const API = '/api'
export const API_DOCS = '/api.json'
export const API_SWAGGER = '/docs'
export const API_HEALTH_CHECK = '/healthz'

export const API_LOGIN = '/login'
export const API_LOGOUT = '/logout'

export const API_CALCULATION_PRICE = 'calculations/price'

export const IMPORT = 'import'
export const EXPORT = 'export'
export const PRINT = 'print'
export const COPY = 'copy'
export const FINISH = 'finish'
export const TREE = 'tree'
export const WAREHOUSE = 'warehouse'

export const API_OPENAI_DESCRIPTION = '/openai/description'

export const generateResourcePath = (...args) => `/${compact(args).join('/')}`

export {MEGASHOP}
