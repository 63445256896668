import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import MuiTextField from '../mui/TextField'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(1)} 0`,
    width: ({wide}) => wide && '400px',

    [theme.breakpoints.down('sm')]: {
      width: ({wide}) => wide && '225px',
    },
  },
}))

const TextInput = ({
  label,
  multiline,
  wide,
  error,
  helperText,
  required,
  fullWidth,
  disabled,
  ...props
}) => {
  const classes = useStyles({wide})

  return (
    <MuiTextField
      className={classes.root}
      label={label}
      multiline={multiline}
      error={error}
      helperText={helperText}
      required={required}
      fullWidth={fullWidth}
      disabled={disabled}
      {...props}
    />
  )
}

TextInput.propTypes = {
  label: PropTypes.string,
  multiline: PropTypes.bool,
  wide: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

export default TextInput
