import MuiTable from '@mui/material/Table'
import PropTypes from 'prop-types'

export const override = {
  // Empty
}

const Table = ({children, ...props}) => (
  <MuiTable {...props}>{children}</MuiTable>
)

Table.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Table
