import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {isRequired, withForm} from 'common-fe/forms'
import PropTypes from 'prop-types'
import {useMemo} from 'react'
import {FieldArray} from 'react-final-form-arrays'
import * as resources from '../../../../../constants/resources'
import * as baseSchemas from '../../../../common/baseSchemas'
import * as schemas from '../../../../common/schemas'
import Widget from '../../../components/containers/Widget/Widget'
import WidgetContainer from '../../../components/containers/Widget/WidgetContainer'
import {
  FormSection,
  IconButton,
  Columns,
  Typography,
} from '../../../components/visual'
import {useAsyncUniqueValidate} from '../../../hooks/useAsyncUniqueValidate'
import TextInput from '../../form/TextInput/TextInput'

const ManufactureFields = () => {
  const validateName = useAsyncUniqueValidate({
    app: null,
    resource: resources.CLIENTS,
    column: 'name',
  })
  return (
    <Columns>
      <div>
        <TextInput
          name="name"
          label="Meno"
          required={isRequired(schemas.client.name)}
          fieldProps={{validate: validateName}}
        />
        <TextInput
          name="address1"
          label="Adresa 1"
          required={isRequired(schemas.client.address1)}
        />
        <TextInput
          name="address2"
          label="Adresa 2"
          required={isRequired(schemas.client.address2)}
        />
        <TextInput
          name="city"
          label="Mesto"
          required={isRequired(schemas.client.city)}
        />
        <TextInput
          name="zipCode"
          label="PSČ"
          required={isRequired(schemas.client.zipCode)}
        />
      </div>
      <div>
        <TextInput
          name="country"
          label="Krajina"
          required={isRequired(schemas.client.country)}
        />
        <TextInput
          name="ico"
          label="IČO"
          required={isRequired(schemas.client.ico)}
        />
        <TextInput
          name="dic"
          label="DIČ"
          required={isRequired(schemas.client.dic)}
        />
        <TextInput
          name="icDph"
          label="DIČ DPH"
          required={isRequired(schemas.client.icDph)}
        />
        <TextInput
          name="discount"
          label="Zľava"
          required={isRequired(schemas.client.discount)}
        />
      </div>
    </Columns>
  )
}

const StatutoriesFields = () => {
  return (
    <FieldArray name="statutories">
      {({fields}) => (
        <>
          {fields.map((name, index) => {
            return (
              <div key={name}>
                <TextInput
                  name={`${name}.fullName`}
                  label="Celé meno"
                  required
                />
                <TextInput name={`${name}.email`} label="E-mail" />
                <TextInput name={`${name}.phone`} label="Telefónne číslo" />
                <TextInput name={`${name}.function`} label="Funkcia" />
                <IconButton
                  color="secondary"
                  onClick={() => fields.remove(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            )
          })}
          <IconButton onClick={() => fields.push({})}>
            <AddIcon />
          </IconButton>
        </>
      )}
    </FieldArray>
  )
}

const layoutName = 'clientsLayout'

const ClientsForm = ({clientInputButton, widget}) => {
  return clientInputButton ? (
    <>
      <ManufactureFields />
      <Typography variant="h6">Kontaktné osoby</Typography>
      <StatutoriesFields />
    </>
  ) : (
    <WidgetContainer name={layoutName} disableDrag disableResize>
      <Widget key="manufacture" data-grid={{w: 2, h: 8, x: 0, y: 0}}>
        <FormSection title="Odoberateľ">
          <ManufactureFields />
        </FormSection>
      </Widget>
      <Widget key="statutories" data-grid={{w: 2, h: 8, x: 2, y: 0}}>
        <FormSection title="Kontaktné osoby">
          <StatutoriesFields />
        </FormSection>
      </Widget>
      {widget ? widget : null}
    </WidgetContainer>
  )
}

ClientsForm.propTypes = {
  clientInputButton: PropTypes.bool,
  widget: PropTypes.node,
}

export const useForm = ({isNewRecord}) => {
  const schema = useMemo(
    () =>
      isNewRecord
        ? baseSchemas.object(schemas.client)
        : baseSchemas.object(schemas.updateClient),
    [isNewRecord],
  )

  return withForm({schema})(({...props}) => (
    <ClientsForm isNewRecord={isNewRecord} {...props} />
  ))
}

export default useForm
