import PropTypes from 'prop-types'
import {forwardRef} from 'react'

const Widget = forwardRef(({children, ...props}, ref) => (
  <div {...props} ref={ref}>
    {children}
  </div>
))

Widget.propTypes = {
  children: PropTypes.node.isRequired,
}

Widget.displayName = 'Widget'

export default Widget
