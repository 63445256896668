import {useNavigate} from '@reach/router'
import PropTypes from 'prop-types'
import generatePath from '../../../../lib/generatePath'
import ControlledDatagrid from '../../containers/Datagrid/ControlledDatagrid'
import {RolesCell} from '../roles/RolesEdit'

const columns = [
  {accessor: 'id', Header: 'ID'},
  {accessor: 'firstName', Header: 'Meno'},
  {accessor: 'lastName', Header: 'Priezvisko'},
  {accessor: 'email', Header: 'Email'},
  {
    accessor: 'role.name',
    Header: 'Rola',
    Cell: RolesCell,
  },
]

const UsersTable = ({
  users,
  total,
  query,
  setQuery,
  selectedIds,
  setSelectedIds,
  ...props
}) => {
  const navigate = useNavigate()

  const handleRowClick = ({original: {id}}) =>
    navigate(generatePath('/users/:userId', {userId: id}))

  return (
    <ControlledDatagrid
      data={users}
      columns={columns}
      total={total}
      query={query}
      setQuery={setQuery}
      currentpage={query?.page}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      onRowClick={handleRowClick}
      {...props}
    />
  )
}

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  total: PropTypes.number,
  query: PropTypes.object,
  setQuery: PropTypes.func,
  selectedIds: PropTypes.array,
  setSelectedIds: PropTypes.func,
}

export default UsersTable
