import * as apps from '../../../constants/apps'

export default {
  [apps.MEGASEAL]: 'Megaseal',
  [apps.MEGAGASKET]: 'Megagasket',
  [apps.MEGACON]: 'Megacon',
  [apps.MEGAPRESS]: 'Megapress',
  [apps.MEGABELT]: 'Megabelt',
  [apps.EXPEDITION]: 'Expedition',
  [apps.MEGASHOP]: 'Megashop',
}
