import {makeStyles} from '@mui/styles'
import {isFunction} from 'lodash'
import PropTypes from 'prop-types'
import MuiTableRow from '../mui/TableRow'

const useStyles = makeStyles({
  root: ({customRowStyles, onClick}) => ({
    cursor: onClick ? 'pointer' : 'default',
    ...customRowStyles,
  }),
})

const TableRow = ({customRowStyles, onClick, children, ...props}) => {
  const classes = useStyles({onClick, customRowStyles})

  return (
    <MuiTableRow
      hover={isFunction(onClick)}
      className={classes.root}
      onClick={onClick}
      {...props}
    >
      {children}
    </MuiTableRow>
  )
}

TableRow.propTypes = {
  customRowStyles: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default TableRow
