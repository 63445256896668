import Skeleton from '@mui/material/Skeleton'
import {makeStyles} from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  skeleton: {
    width: '100%',
    height: '100%',
    margin: '85px 30px',

    [theme.breakpoints.down('sm')]: {
      width: '70%',
      marginLeft: 'auto',
    },
  },
  skeletonContainer: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  title: {
    marginRight: 'auto',

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      maxWidth: '80%',
    },
  },
  button: {
    marginRight: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  skeletonSpace: {
    marginTop: theme.spacing(2),
  },
}))

const Loading = () => {
  const classes = useStyles()

  return (
    <div className={classes.skeleton}>
      <div className={classes.skeletonContainer}>
        <Skeleton
          className={classes.title}
          variant="text"
          height={70}
          width={300}
        />
        <Skeleton
          className={classes.button}
          variant="text"
          height={70}
          width={125}
        />
        <Skeleton variant="text" height={70} width={125} />
      </div>
      <Skeleton className={classes.skeletonSpace} variant="rect" height={500} />
    </div>
  )
}

export default Loading
