import MuiDialogContentText from '@mui/material/DialogContentText'
import PropTypes from 'prop-types'

export const override = {
  // Empty
}

const DialogContentText = ({children, ...props}) => (
  <MuiDialogContentText {...props}>{children}</MuiDialogContentText>
)

DialogContentText.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DialogContentText
