import SaveIcon from '@mui/icons-material/Save'
import {useNavigate} from '@reach/router'
import * as resources from '../../../../../constants/resources'
import generatePath from '../../../../lib/generatePath'
import {useCreateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import Button from '../../containers/Button/Button'
import {ScreenHeader} from '../../visual'
import UsersForm from './UsersForm'

const UsersCreate = () => {
  const navigate = useNavigate()
  const createUser = useCreateResource(null, resources.USERS)
  const showAlert = useAlert()

  const handleSubmit = async (values) => {
    const {id} = await createUser.mutateAsync(values)

    navigate(generatePath('/users/:userId', {userId: id}))
    showAlert('Vytvorené', 'success')
    // Still need to handle errors somehow
  }

  return (
    <UsersForm onSubmit={handleSubmit}>
      {({submitting, form, handleSubmit}) => (
        <>
          <ScreenHeader title="Vytvoriť používateľa">
            <Button
              icon={<SaveIcon />}
              variant="contained"
              disabled={submitting}
              onClick={handleSubmit}
            >
              Uložiť
            </Button>
          </ScreenHeader>
          {form}
        </>
      )}
    </UsersForm>
  )
}

export default UsersCreate
