import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import {isEmpty} from 'lodash'
import PropTypes from 'prop-types'
import {useState} from 'react'
import {Modal} from '../../visual'
import Button from '../Button/Button'

const DeleteButton = ({onDelete, selectedIds, ...props}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleDelete = () => {
    onDelete()
    setOpen(false)
  }

  if (isEmpty(selectedIds)) return null
  return (
    <>
      <Button
        icon={<DeleteIcon />}
        onClick={handleOpen}
        color="secondary"
        variant="contained"
        {...props}
      >
        Odstrániť {selectedIds.length}
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Modal.Title>Naozaj chcete vymazať označené záznamy?</Modal.Title>
        <Modal.Actions>
          <Button icon={<CloseIcon />} onClick={handleClose}>
            Zavrieť
          </Button>
          <Button
            icon={<DeleteIcon />}
            color="secondary"
            variant="contained"
            onClick={handleDelete}
          >
            Zmazať
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  selectedIds: PropTypes.array,
}

export default DeleteButton
