import MuiDrawer from '@mui/material/Drawer'
import {makeStyles} from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {override as toolbarOverride} from './Toolbar'

export const override = {
  styleOverrides: {
    paper: {
      position: 'relative',
      minHeight: `calc(100vh - ${toolbarOverride.styleOverrides.root.minHeight})`,
      height: '100%',
      width: '200px',
    },
    paperAnchorDockedLeft: {
      borderRight: 'none',
    },
  },
}

const drawerWidth = '270px'
export const drawerWidthClosed = '57px'

const useStyles = makeStyles((theme) => ({
  drawer: {
    marginTop: '57px',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      zIndex: '100',
    },
  },
  drawerOpen: {
    width: drawerWidth,
    background: `linear-gradient(to right,
      ${theme.palette.primary.main} 57px,
      ${theme.palette.background.dark} 20px);`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    background: `linear-gradient(to right,
      ${theme.palette.primary.main} 57px,
      ${theme.palette.background.dark} 20px);`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
}))

const Drawer = ({children, open, ...props}) => {
  const classes = useStyles()

  return (
    <MuiDrawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      {...props}
    >
      {children}
    </MuiDrawer>
  )
}

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
}

export default Drawer
