import MuiListItemButton from '@mui/material/ListItemButton'
import PropTypes from 'prop-types'

export const override = {}

const ListItemButton = ({children, ...props}) => (
  <MuiListItemButton {...props}>{children}</MuiListItemButton>
)

ListItemButton.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ListItemButton
