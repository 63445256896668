import MuiTablePagination from '@mui/material/TablePagination'

export const override = {
  // Empty
}

const TablePagination = ({...props}) => (
  <MuiTablePagination component="div" {...props} />
)

export default TablePagination
