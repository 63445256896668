import CreateIcon from '@mui/icons-material/Create'
import {Link} from '@reach/router'
import {useState} from 'react'
import * as resources from '../../../../../constants/resources'
import {generateResourcePath} from '../../../../../constants/routes'
import {useDeleteManyResources, useListResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import useLocalSetting from '../../../hooks/useLocalSetting'
import Button from '../../containers/Button/Button'
import DeleteButton from '../../containers/DeleteButton/DeleteButton'
import Filters from '../../containers/Filters/Filters'
import SearchBoxInput from '../../form/SearchBoxInput/SearchBoxInput'
import {ScreenHeader} from '../../visual'
import ClientsTable from './ClientsTable'

export const queryDef = ({query} = {}) => ({
  app: '',
  resource: resources.CLIENTS,
  query,
})

const initialQuery = {
  page: 0,
  pageSize: 10,
  filter: {deleted: false},
}

const queryName = 'clientsQuery'

const ClientsList = () => {
  const [selectedIds, setSelectedIds] = useState([])
  const [query, setQuery] = useLocalSetting(queryName, initialQuery)
  const {
    data: clients,
    total,
    refetch,
    isFetching,
  } = useListResource(queryDef({query}))
  const deleteClients = useDeleteManyResources(null, resources.CLIENTS)
  const showAlert = useAlert()

  const handleDelete = async () => {
    await deleteClients.mutateAsync(selectedIds)
    setSelectedIds([])
    await refetch()
    showAlert('Vymazané', 'success')
  }

  return (
    <>
      <ScreenHeader title="Odoberatelia">
        <DeleteButton
          onDelete={handleDelete}
          selectedIds={selectedIds}
          disabled={isFetching}
          data-test-id="deleteButton"
        />
        <Button
          icon={<CreateIcon />}
          variant="contained"
          component={Link}
          to={generateResourcePath('clients', 'create')}
        >
          Vytvoriť
        </Button>
      </ScreenHeader>
      <Filters query={query} setQuery={setQuery}>
        <SearchBoxInput
          fullWidth={false}
          name="q"
          label="Vyhľadať odoberateľa"
        />
      </Filters>
      <ClientsTable
        clients={clients}
        total={total}
        query={query}
        setQuery={setQuery}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
      />
    </>
  )
}

export default ClientsList
