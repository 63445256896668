import {Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import {showErrorOnChange} from 'common-fe/forms'
import PropTypes from 'prop-types'
import {useField} from 'react-final-form'
import {StaticField, TextInput as VisualTextInput} from '../../visual'

const TextInput = ({
  disableSpacing,
  readOnly,
  name,
  label,
  wide,
  required,
  helperText,
  fullWidth,
  showError = showErrorOnChange,
  fieldProps,
  ...props
}) => {
  const {
    input: {value, type, onChange, ...restInput},
    meta,
  } = useField(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})

  return (
    <Choose>
      <When condition={!readOnly}>
        <VisualTextInput
          fullWidth={fullWidth}
          label={label}
          helperText={helperTextOrError}
          error={isError}
          onChange={onChange}
          name={name}
          value={value}
          type={type}
          required={required}
          wide={wide}
          inputProps={{required, ...restInput}}
          {...props}
        />
      </When>
      <Otherwise>
        <StaticField label={label} disableSpacing={disableSpacing}>
          {value || '–'}
        </StaticField>
      </Otherwise>
    </Choose>
  )
}

TextInput.defaultProps = {
  fullWidth: true,
}

TextInput.propTypes = {
  disableSpacing: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  isEditable: PropTypes.bool,
  required: PropTypes.bool,
  wide: PropTypes.bool,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  showError: PropTypes.func,
  fieldProps: PropTypes.object,
}

export default TextInput
