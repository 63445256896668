import {AutoSave} from 'common-fe/forms'
import PropTypes from 'prop-types'
import {Form, FormSpy} from 'react-final-form'
import useIsMounted from '../../../hooks/useIsMounted'
import {FiltersContainer, CircularProgress} from '../../visual'

const Filters = ({query, setQuery, children}) => {
  const isMounted = useIsMounted()
  const handleSave = async (filter) => {
    if (isMounted.current) setQuery({filter, page: 0})
  }
  return (
    <Form onSubmit={handleSave} initialValues={query?.filter} subscription={{}}>
      {() => (
        <FiltersContainer
          loading={
            <FormSpy subscription={{values: true}}>
              {({values}) => (
                <AutoSave debounce={700} save={handleSave} values={values}>
                  <CircularProgress size={40} />
                </AutoSave>
              )}
            </FormSpy>
          }
        >
          {children}
        </FiltersContainer>
      )}
    </Form>
  )
}

Filters.propTypes = {
  query: PropTypes.object.isRequired,
  setQuery: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default Filters
