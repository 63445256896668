import MuiTableBody from '@mui/material/TableBody'
import PropTypes from 'prop-types'

export const override = {
  // Empty
}

const TableBody = ({children, ...props}) => (
  <MuiTableBody {...props}>{children}</MuiTableBody>
)

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TableBody
