import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: `${theme.spacing(2.5)} 0`,
    margin: `${theme.spacing(1)} 0`,
  },
  label: {
    position: 'absolute',
    top: '-6px',
    fontSize: '12px',
    color: theme.palette.text.secondary,

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.65rem',
    },
  },
}))

const StaticField = ({disableSpacing, label, children, ...props}) => {
  const classes = useStyles()

  return (
    <div className={disableSpacing ? '' : classes.root} {...props}>
      <span className={classes.label}>{label}</span>
      {children}
    </div>
  )
}

StaticField.propTypes = {
  disableSpacing: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default StaticField
