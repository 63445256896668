import {memoize, some} from 'lodash'
import {useCallback} from 'react'
import {generateResourcePath} from '../../../constants/routes'
import {api} from '../utils/api'
import {readSession} from './auth'

export const useAsyncUniqueValidate = ({app, resource, column, deleted}) => {
  const route = generateResourcePath(app, resource)
  // UseCallback can't be inside memoize function because it could cause multiple unnecessary requests
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadResources = useCallback(
    memoize((value) => {
      const session = readSession()
      const query = {
        filter: {
          [column]: value,
          deleted: deleted ? undefined : false,
        },
      }
      return api('GET', route, {query, sessionToken: session.token})
    }),
    [column, deleted, route],
  )
  return useCallback(
    async (value, _values, {pristine, valid}) => {
      if (!value || !valid || pristine) return
      const res = await loadResources(value)
      if (!res?.total) return
      if (some(res.data, {[column]: value})) return 'Hodnota musí byť unikátna'
    },
    [column, loadResources],
  )
}
