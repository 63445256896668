import ClearIcon from '@mui/icons-material/Clear'
import DoneIcon from '@mui/icons-material/Done'
import {Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import {showErrorOnChange} from 'common-fe/forms'
import PropTypes from 'prop-types'
import {useField} from 'react-final-form'
import {StaticField, Switch} from '../../visual'

const BooleanInput = ({
  readOnly,
  name,
  label,
  required,
  helperText,
  fullWidth,
  showError = showErrorOnChange,
  fieldProps,
  ...props
}) => {
  const {
    input: {value, onChange, checked, ...restInput},
    meta,
  } = useField(name, {...fieldProps, type: 'checkbox'})
  const {isError, helperTextOrError} = showError({meta, helperText})

  return (
    <Choose>
      <When condition={!readOnly}>
        <Switch
          fullWidth={fullWidth}
          name={name}
          label={label}
          value={value}
          onChange={onChange}
          checked={checked}
          helperText={helperTextOrError}
          error={isError}
          required={required}
          inputProps={{required, ...restInput}}
          {...props}
        />
      </When>
      <Otherwise>
        <StaticField label={label}>
          <Choose>
            <When condition={checked}>
              <DoneIcon color="primary" />
            </When>
            <Otherwise>
              <ClearIcon color="error" />
            </Otherwise>
          </Choose>
        </StaticField>
      </Otherwise>
    </Choose>
  )
}

BooleanInput.defaultProps = {
  fullWidth: true,
}

BooleanInput.propTypes = {
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  showError: PropTypes.func,
  fieldProps: PropTypes.object,
}

export default BooleanInput
