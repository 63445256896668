/**
 * Values in `seal.mandatoryDimensions` and `seal.additionalDimensions` columns:
 */

export const NA = 'NA'
export const NI = 'NI'
export const NH = 'NH'
export const N = 'N'
export const L = 'L'
export const CSA = 'CSA'
export const CSR = 'CSR'
export const AF = 'AF'
export const AF2 = 'AF2'
export const H = 'H'
export const AFBR = 'AFBR'
export const BRH = 'BRH'
export const S = 'S'
export const NL = 'NL'
export const AV = 'AV'
export const IV = 'IV'
export const A = 'A'
export const AD = 'AD'
export const AFO = 'AFO'
export const AF1 = 'AF1'
export const AF3 = 'AF3'
export const AF4 = 'AF4'
export const AF10 = 'AF10'
export const AF12 = 'AF12'
export const AF15 = 'AF15'
export const AF25 = 'AF25'
export const AF27 = 'AF27'
export const AFF = 'AFF'
export const AFF2 = 'AFF2'
export const AFR2 = 'AFR2'
export const AFR13 = 'AFR13'
export const AL = 'AL'
export const AL2 = 'AL2'
export const AV1 = 'AV1'
export const AV2 = 'AV2'
export const AV10 = 'AV10'
export const AV11 = 'AV11'
export const AV14 = 'AV14'
export const AVH11 = 'AVH11'
export const B = 'B'
export const CORD_L = 'CORD_L'
export const CSB = 'CSB'
export const CSA1 = 'CSA1'
export const CSA2 = 'CSA2'
export const CSA3 = 'CSA3'
export const CSA4 = 'CSA4'
export const CSA10 = 'CSA10'
export const CSA11 = 'CSA11'
export const CSA12 = 'CSA12'
export const CSA13 = 'CSA13'
export const CSA14 = 'CSA14'
export const CSA15 = 'CSA15'
export const CSA25 = 'CSA25'
export const CSA26 = 'CSA26'
export const CSA27 = 'CSA27'
export const LIP_CSA = 'LIP_CSA'
export const BR_CSA = 'BR_CSA'
export const CSF = 'CSF'
export const CSN = 'CSN'
export const CSR2 = 'CSR2'
export const CSR3 = 'CSR3'
export const CSR4 = 'CSR4'
export const CSR11 = 'CSR11'
export const DE = 'DE'
export const EB = 'EB'
export const ET = 'ET'
export const FAX = 'FAX'
export const FIX = 'FIX'
export const HE = 'HE'
export const HS = 'HS'
export const H1 = 'H1'
export const H2 = 'H2'
export const H3 = 'H3'
export const H4 = 'H4'
export const H10 = 'H10'
export const H11 = 'H11'
export const H11E = 'H11E'
export const H12 = 'H12'
export const H25 = 'H25'
export const H26 = 'H26'
export const H27 = 'H27'
export const H32 = 'H32'
export const HPM = 'HPM'
export const CH = 'CH'
export const ID = 'ID'
export const IF = 'IF'
export const IF1 = 'IF1'
export const IF2 = 'IF2'
export const IF15 = 'IF15'
export const IFF = 'IFF'
export const IQ = 'IQ'
export const IV2 = 'IV2'
export const IV11 = 'IV11'
export const IVH11 = 'IVH11'
export const L1 = 'L1'
export const L1A = 'L1A'
export const L2 = 'L2'
export const L2A = 'L2A'
export const L2B = 'L2B'
export const L3 = 'L3'
export const L4 = 'L4'
export const M = 'M'
export const MD = 'MD'
export const MD1 = 'MD1'
export const NS = 'NS'
export const NHV = 'NHV'
export const Q = 'Q'
export const OR_IF = 'OR_IF'
export const QB = 'QB'
export const QT = 'QT'
export const S1 = 'S1'
export const S2 = 'S2'
export const S3 = 'S3'
export const SH = 'SH'
export const T = 'T'
export const VA26 = 'VA26'
export const Z = 'Z'
export const NH_CTRL = 'NH_CTRL'
export const S_CTRL = 'S_CTRL'
export const H_CTRL = 'H_CTRL'
export const L_CTRL = 'L_CTRL'

export const ALL_DIMENSIONS = [
  NA,
  NI,
  NH,
  N,
  L,
  CSA,
  CSR,
  AF,
  AF2,
  H,
  AFBR,
  BRH,
  S,
  NL,
  AV,
  IV,
  A,
  AD,
  AFO,
  AF1,
  AF3,
  AF4,
  AF10,
  AF12,
  AF15,
  AF25,
  AF27,
  AFF,
  AFF2,
  AFR2,
  AFR13,
  AL,
  AL2,
  AV1,
  AV2,
  AV10,
  AV11,
  AV14,
  AVH11,
  B,
  CORD_L,
  CSB,
  CSA1,
  CSA2,
  CSA3,
  CSA4,
  CSA10,
  CSA11,
  CSA12,
  CSA13,
  CSA14,
  CSA15,
  CSA25,
  CSA26,
  CSA27,
  LIP_CSA,
  BR_CSA,
  CSF,
  CSN,
  CSR2,
  CSR3,
  CSR4,
  CSR11,
  DE,
  EB,
  ET,
  FAX,
  FIX,
  HE,
  HS,
  H1,
  H2,
  H3,
  H4,
  H10,
  H11,
  H11E,
  H12,
  H25,
  H26,
  H27,
  H32,
  HPM,
  CH,
  ID,
  IF,
  IF1,
  IF2,
  IF15,
  IFF,
  IQ,
  IV2,
  IV11,
  IVH11,
  L1,
  L1A,
  L2,
  L2A,
  L2B,
  L3,
  L4,
  M,
  MD,
  MD1,
  NS,
  NHV,
  Q,
  OR_IF,
  QB,
  QT,
  S1,
  S2,
  S3,
  SH,
  T,
  VA26,
  Z,
  NH_CTRL,
  S_CTRL,
  H_CTRL,
  L_CTRL,
]
