import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {format} from 'date-fns'
import PropTypes from 'prop-types'
import Link from '../mui/Link'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '30px',
  },
  root: {
    display: 'block',
    color: 'grey',
    marginLeft: theme.spacing(1),
  },
}))

const Footer = ({version, date, changelog, ...props}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography variant="caption" className={classes.root} {...props}>
        © {format(new Date(), 'Y')} MEGAbelt SK, s.r.o.&nbsp;|&nbsp;
        Verzia&nbsp;
        <Link href={changelog} target="_blank" rel="noopener noreferrer">
          {version}
        </Link>
        &nbsp; build z {date}&nbsp; Vyrobené v&nbsp;
        <Link
          href="https://www.sited.sk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sited, s.r.o.
        </Link>
      </Typography>
    </div>
  )
}

Footer.propTypes = {
  version: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  changelog: PropTypes.string.isRequired,
}

export default Footer
