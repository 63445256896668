import SaveIcon from '@mui/icons-material/Save'
import {useNavigate} from '@reach/router'
import * as resources from '../../../../../constants/resources'
import generatePath from '../../../../lib/generatePath'
import {useCreateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import Button from '../../containers/Button/Button'
import {ScreenHeader} from '../../visual'
import useClientsForm from './ClientsForm'

const ClientsCreate = () => {
  const navigate = useNavigate()
  const createClient = useCreateResource(null, resources.CLIENTS)
  const showAlert = useAlert()
  const ClientsForm = useClientsForm({isNewRecord: true})

  const handleSubmit = async (values) => {
    const {id} = await createClient.mutateAsync(values)

    navigate(generatePath('/clients/:clientId', {clientId: id}))
    showAlert('Vytvorené', 'success')
    // Still need to handle errors somehow
  }

  return (
    <ClientsForm onSubmit={handleSubmit}>
      {({submitting, form, handleSubmit}) => (
        <>
          <ScreenHeader title="Vytvoriť odoberateľa">
            <Button
              icon={<SaveIcon />}
              variant="contained"
              disabled={submitting}
              onClick={handleSubmit}
            >
              Uložiť
            </Button>
          </ScreenHeader>
          {form}
        </>
      )}
    </ClientsForm>
  )
}

export default ClientsCreate
