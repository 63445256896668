import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import * as resources from '../../../../../../constants/resources'
import {useDeleteResource} from '../../../../hooks/api'
import {useAlert} from '../../../../hooks/useAlert'
import Button from '../../../containers/Button/Button'

const DeleteSessionButton = ({row, refetch, isFetching}) => {
  const session = row.original
  const deleteSession = useDeleteResource(null, resources.SESSIONS)
  const showAlert = useAlert()

  const handleDelete = async () => {
    await deleteSession.mutateAsync(session.id)
    await refetch()
    showAlert('Vymazané', 'success')
  }

  return (
    <Button
      icon={<DeleteIcon />}
      variant="contained"
      color="secondary"
      onClick={handleDelete}
      disabled={isFetching}
    >
      Vymazať
    </Button>
  )
}

DeleteSessionButton.propTypes = {
  row: PropTypes.object,
  refetch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

export default DeleteSessionButton
