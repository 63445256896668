import {isRequired, showErrorOnChange, withForm} from 'common-fe/forms'
import {isEmpty} from 'lodash'
import PropTypes from 'prop-types'
import * as resources from '../../../../../constants/resources'
import * as baseSchemas from '../../../../common/baseSchemas'
import * as schemas from '../../../../common/schemas'
import EnumInput from '../../form/EnumInput/EnumInput'
import PasswordInput from '../../form/PasswordInput/PasswordInput'
import ReferenceInput from '../../form/ReferenceInput/ReferenceInput'
import TextInput from '../../form/TextInput/TextInput'
import {Columns} from '../../visual'

const ProfileForm = ({initialValues}) => (
  <Columns>
    <div>
      <TextInput
        name="firstName"
        label="Meno"
        required={isRequired(schemas.user.firstName)}
      />
      <TextInput
        name="lastName"
        label="Priezvisko"
        required={isRequired(schemas.user.lastName)}
      />
      <TextInput name="email" label="Email" readOnly />
      <ReferenceInput
        name="roleId"
        label="Rola"
        optionText="name"
        resource={resources.ROLES}
        defaultValue={initialValues?.role}
        readOnly
      />
      <EnumInput
        name="apps"
        label="Aplikácie"
        enumName="apps"
        multiple
        readOnly
      />
    </div>
    <div>
      <PasswordInput
        name="oldPassword"
        label="Pôvodné heslo"
        autoComplete="off"
        helperText="Heslo vyplňte len ak ho chcete zmeniť"
        fieldProps={{
          validate: (oldPassword, {password}) => {
            if (!isEmpty(password) && isEmpty(oldPassword)) {
              return 'Pri zmene hesla je najskôr potrebné zadať staré heslo'
            }
          },
        }}
      />
      <PasswordInput
        name="password"
        label="Nové heslo"
        showError={showErrorOnChange}
        fieldProps={{
          validate: (password, {confirmPassword}) => {
            if (password !== confirmPassword) return 'Heslá sa musia zhodovať'
          },
        }}
        required={false}
      />
      <PasswordInput
        name="confirmPassword"
        label="Nové heslo znova"
        showError={showErrorOnChange}
        fieldProps={{
          validate: (confirmPassword, {password}) => {
            if (password !== confirmPassword) return 'Heslá sa musia zhodovať'
          },
        }}
      />
    </div>
  </Columns>
)

ProfileForm.propTypes = {
  initialValues: PropTypes.object,
}

const schema = baseSchemas.object({
  firstName: schemas.user.firstName,
  lastName: schemas.user.lastName,
  email: schemas.user.email,
  oldPassword: schemas.user.oldPassword,
  password: baseSchemas.optional(schemas.user.password),
})

export default withForm({schema})(ProfileForm)
