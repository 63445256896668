import * as contentTypes from '../../../../../constants/contentTypes'

export default {
  [contentTypes.FLEX]: 'Rozmiestnenie',
  [contentTypes.RICH_TEXT]: 'Formátovaný text',
  [contentTypes.TABS]: 'Taby',
  [contentTypes.TAB]: 'Tab',
  [contentTypes.IMAGE]: 'Obrázok',
  [contentTypes.FILE]: 'Súbor',
  [contentTypes.BANNER]: 'Baner',
}
