import {isUndefined} from 'lodash'
import PropTypes from 'prop-types'
import SimpleDatagrid from '../../containers/Datagrid/SimpleDatagrid'
import dateCell from '../../containers/Datagrid/cells/dateCell'
import DeleteSessionButton from './components/DeleteSessionButton'

const isBrowserInfoEmpty = (browser, version, os, platform) => {
  return (
    isUndefined(browser) &&
    isUndefined(version) &&
    isUndefined(os) &&
    isUndefined(platform)
  )
}

const BrowserCell = ({value}) => {
  if (!value) return '—'
  const {browser, version, os, platform} = value

  if (isBrowserInfoEmpty(browser, version, os, platform)) return '—'
  return `${isUndefined(browser) ? '-' : browser} ${isUndefined(version) ? '-' : version} na
  ${isUndefined(os) ? '-' : os} (${isUndefined(platform) ? '-' : platform})`
}

const columns = [
  {
    accessor: 'validUntil',
    Header: 'Platnosť do',
    Cell: dateCell('Do konca relácie'),
  },
  {
    accessor: 'lastBrowser',
    Header: 'Prehliadač',
    Cell: BrowserCell,
  },
  {accessor: 'lastIp', Header: 'IP adresa'},
  {
    accessor: 'deleteSession',
    Cell: DeleteSessionButton,
    disableSortBy: true,
  },
]

const ProfileSessionsTable = ({sessions, total, ...props}) => (
  <SimpleDatagrid data={sessions} columns={columns} total={total} {...props} />
)

ProfileSessionsTable.propTypes = {
  sessions: PropTypes.array.isRequired,
  total: PropTypes.number,
}

export default ProfileSessionsTable
