import {zipObject} from 'lodash'
import {ALL_DIMENSIONS} from '../../../../../constants/megaseal/megasealDimensions'
import megasealComponentTypes from './megasealComponentTypes'
import megasealManufactureStatuses from './megasealManufactureStatuses'

export const DIMENSIONS = 'megasealDimensions'
export const MANUFACTURE_STATUSES = 'megasealManufactureStatuses'
export const COMPONENT_TYPES = 'megasealComponentTypes'

export default {
  [DIMENSIONS]: zipObject(ALL_DIMENSIONS, ALL_DIMENSIONS),
  [MANUFACTURE_STATUSES]: megasealManufactureStatuses,
  [COMPONENT_TYPES]: megasealComponentTypes,
}
