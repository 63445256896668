import {makeStyles} from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import List from '../mui/List'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
  },
  navigationOpen: {
    width: '270px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  navigationClose: {
    width: '57px',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}))

const NavigationList = ({children, open, ...props}) => {
  const classes = useStyles()

  return (
    <List
      className={clsx(classes.root, {
        [classes.navigationOpen]: open,
        [classes.navigationClose]: !open,
      })}
      {...props}
    >
      {children}
    </List>
  )
}

NavigationList.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default NavigationList
