import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import {Children} from 'react'
import {Responsive, WidthProvider} from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import useLocalSetting from '../../../hooks/useLocalSetting'

const ResponsiveGridLayout = WidthProvider(Responsive)

const useStyles = makeStyles((theme) => ({
  gridLayout: {
    margin: '-10px',
    '& > *': {
      border: ({disableDrag}) =>
        !disableDrag && `2px dashed ${theme.palette.primary.main}`,
      borderRadius: ({disableDrag}) => !disableDrag && theme.shape.borderRadius,
      cursor: ({disableDrag}) => !disableDrag && 'pointer',
    },
  },
}))

const WidgetContainer = ({
  name,
  disableDrag,
  disableResize,
  children,
  ...props
}) => {
  const [layouts, setLayouts] = useLocalSetting(name, {})
  const classes = useStyles({disableDrag})

  const handleLayoutsChange = (_layout, allLayouts) => {
    setLayouts(allLayouts)
  }

  return (
    <ResponsiveGridLayout
      breakpoints={{md: 700, sm: 0}}
      cols={{md: 4, sm: 1}}
      layouts={layouts}
      rowHeight={60}
      onLayoutChange={handleLayoutsChange}
      isDraggable={!disableDrag}
      isResizable={!disableResize}
      className={classes.gridLayout}
      {...props}
    >
      {Children.toArray(children).filter(Boolean)}
    </ResponsiveGridLayout>
  )
}

WidgetContainer.defaultProps = {
  disableDrag: false,
  disableResize: false,
}

WidgetContainer.propTypes = {
  name: PropTypes.string.isRequired,
  disableDrag: PropTypes.bool,
  disableResize: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default WidgetContainer
