import MuiMenu from '@mui/material/Menu'
import PropTypes from 'prop-types'
import {forwardRef} from 'react'

export const override = {}

const Menu = forwardRef(({children, ...props}, ref) => {
  return (
    <MuiMenu ref={ref} {...props}>
      {children}
    </MuiMenu>
  )
})

Menu.propTypes = {
  children: PropTypes.node.isRequired,
}

Menu.displayName = 'Menu'

export default Menu
