import MuiDialogContent from '@mui/material/DialogContent'
import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'

export const override = {}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '26rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: '10rem',
    },
  },
}))

const DialogContent = ({children, ...props}) => {
  const classes = useStyles()
  return (
    <MuiDialogContent className={classes.root} {...props}>
      {children}
    </MuiDialogContent>
  )
}

DialogContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DialogContent
