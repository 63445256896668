import MuiFab from '@mui/material/Fab'

export const override = {
  styleOverrides: {
    sizeSmall: {
      minWidth: '35px',
      width: '35px',
      height: '35px',
    },
  },
}

const Fab = ({...props}) => <MuiFab color="primary" size="small" {...props} />

Fab.propTypes = {
  // Empty
}

export default Fab
