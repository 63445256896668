import {isRequired, showErrorOnBlur, withForm} from 'common-fe/forms'
import PropTypes from 'prop-types'
import * as baseSchemas from '../../../../common/baseSchemas'
import * as schemas from '../../../../common/schemas'
import Button from '../../containers/Button/Button'
import BooleanInput from '../../form/BooleanInput/BooleanInput'
import PasswordInput from '../../form/PasswordInput/PasswordInput'
import TextInput from '../../form/TextInput/TextInput'

const LoginForm = ({submitting, submitError}) => (
  <>
    <TextInput
      name="email"
      label="Email"
      required={isRequired(schemas.login.email)}
      showError={showErrorOnBlur}
    />
    <PasswordInput
      name="password"
      label="Heslo"
      required={isRequired(schemas.login.password)}
    />
    <BooleanInput
      name="remember"
      label="Zostať prihlásený"
      required={isRequired(schemas.login.remember)}
    />
    <Button variant="contained" type="submit" disabled={submitting}>
      Prihlásiť
    </Button>
    {submitError && <span>{submitError}</span>}
  </>
)

LoginForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  submitError: PropTypes.string,
}

const schema = baseSchemas.object(schemas.login)

export default withForm({schema})(LoginForm)
