import {Link} from '@reach/router'
import * as apps from '../../../../../constants/apps'
import {generateResourcePath} from '../../../../../constants/routes'
import {hasPermissions} from '../../../../common/auth'
import LogoExpedition from '../../../apps/expedition/assets/megabelt_expedition.svg'
import LogoMegabelt from '../../../apps/megabelt/assets/megabelt_belt.svg'
import LogoMegacon from '../../../apps/megacon/assets/megabelt_con.svg'
import LogoMegagasket from '../../../apps/megagasket/assets/megabelt_gasket.svg'
import LogoMegapress from '../../../apps/megapress/assets/megabelt_press.svg'
import LogoMegaseal from '../../../apps/megaseal/assets/megabelt_seal.svg'
import LogoMegashop from '../../../apps/megashop/assets/megabelt_shop.svg'
import {useSession} from '../../../hooks/auth'
import {ScreenHeader} from '../../visual'
import PaperButton from '../../visual/PaperButton/PaperButton'
import PaperButtonContainer from '../../visual/PaperButton/PapperButtonContainer'

const Dashboard = () => {
  const session = useSession()

  return (
    <>
      <ScreenHeader title="Vitajte" />
      <PaperButtonContainer>
        <PaperButton
          disabled={!hasPermissions(session.apps, [apps.MEGASEAL])}
          component={Link}
          to={generateResourcePath(apps.MEGASEAL)}
        >
          <img src={LogoMegaseal} alt="Logo Megaseal" />
        </PaperButton>
        <PaperButton
          disabled={!hasPermissions(session.apps, [apps.MEGASHOP])}
          component={Link}
          to={generateResourcePath(apps.MEGASHOP)}
        >
          <img src={LogoMegashop} alt="Logo Megashop" />
        </PaperButton>
        <PaperButton
          disabled={!hasPermissions(session.apps, [apps.MEGAGASKET])}
          component={Link}
          to={generateResourcePath(apps.MEGAGASKET)}
        >
          <img src={LogoMegagasket} alt="Logo Megagasket" />
        </PaperButton>
        <PaperButton
          disabled={!hasPermissions(session.apps, [apps.MEGACON])}
          component={Link}
          to={generateResourcePath(apps.MEGACON)}
        >
          <img src={LogoMegacon} alt="Logo Megacon" />
        </PaperButton>
        <PaperButton
          disabled={!hasPermissions(session.apps, [apps.MEGAPRESS])}
          component={Link}
          to={generateResourcePath(apps.MEGAPRESS)}
        >
          <img src={LogoMegapress} alt="Logo Megapress" />
        </PaperButton>
        <PaperButton
          disabled={!hasPermissions(session.apps, [apps.MEGABELT])}
          component={Link}
          to={generateResourcePath(apps.MEGABELT)}
        >
          <img src={LogoMegabelt} alt="Logo Megabelt" />
        </PaperButton>
        <PaperButton
          disabled={!hasPermissions(session.apps, [apps.EXPEDITION])}
          component={Link}
          to={generateResourcePath(apps.EXPEDITION)}
        >
          <img src={LogoExpedition} alt="Logo Expedition" />
        </PaperButton>
      </PaperButtonContainer>
    </>
  )
}

export default Dashboard
