export const SYNTAX_ERROR = 'SYNTAX_ERROR'
export const VALIDATION_ERROR = 'VALIDATION_ERROR'

export const INVALID_AUTHENTICATION_HEADER = 'INVALID_AUTHENTICATION_HEADER'
export const SESSION_EXPIRED_ERROR = 'SESSION_EXPIRED_ERROR'
export const INVALID_CREDENTIALS_ERROR = 'INVALID_CREDENTIALS_ERROR'

export const INVALID_MANDATORY_DIMENSIONS_ERROR =
  'INVALID_MANDATORY_DIMENSIONS_ERROR'
export const UNSUPPORTED_DIMENSIONS_ERROR = 'UNSUPPORTED_DIMENSIONS_ERROR'
export const CALCULATION_UPDATE_ERROR = 'CALCULATION_UPDATE_ERROR'
export const CALCULATION_ALREADY_MANUFACTURED =
  'CALCULATION_ALREADY_MANUFACTURED'
export const MANUFACTURE_ALREADY_TRANSFERRED = 'MANUFACTURE_ALREADY_TRANSFERRED'
export const MANUFACTURE_MRP_STOCK_CODE_MISSING_ERROR =
  'MANUFACTURE_MRP_STOCK_CODE_MISSING_ERROR'
export const TRANSFER_CANNOT_FINISH = 'TRANSFER_CANNOT_FINISH'
export const SCHEDULE_FREQUENCY_INTERVAL_ERROR =
  'SCHEDULE_FREQUENCY_INTERVAL_ERROR'

export const NOT_FOUND_ERROR = 'NOT_FOUND_ERROR'

export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR'
export const AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR'
export const PERMISSIONS_ERROR = 'PERMISSIONS_ERROR'
export const REFERENCE_ERROR = 'REFERENCE_ERROR'
export const UNIQUE_ERROR = 'UNIQUE_ERROR'

export const FILE_TYPE_ERROR = 'FILE_TYPE_ERROR'
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR'
export const SIGNED_URL_ERROR = 'SIGNED_URL_ERROR'

export const CATEGORY_CYCLE_ERROR = 'CATEGORY_CYCLE_ERROR'

export const INVALID_CONTENT_TYPE = 'INVALID_CONTENT_TYPE'

export const ORDER_NOT_DRAFT_ERROR = 'ORDER_NOT_DRAFT_ERROR'
export const NO_ORDER_ITEMS_TO_SUBMIT_ERROR = 'NO_ORDER_ITEMS_TO_SUBMIT_ERROR'
export const ATTRIBUTE_META_UPDATE_ERROR = 'ATTRIBUTE_META_UPDATE_ERROR'

export const EXTERNAL_SERVICE_ERROR = 'EXTERNAL_SERVICE_ERROR'
