import MuiDialogTitle from '@mui/material/DialogTitle'
import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'

export const override = {}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}))

const DialogTitle = ({children, ...props}) => {
  const classes = useStyles()
  return (
    <MuiDialogTitle className={classes.root} {...props}>
      {children}
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DialogTitle
