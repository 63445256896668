import SearchIcon from '@mui/icons-material/Search'
import {showErrorOnChange} from 'common-fe/forms'
import PropTypes from 'prop-types'
import {useField} from 'react-final-form'
import {InputAdornment, TextInput} from '../../visual'

const SearchBoxInput = ({
  name,
  label,
  required,
  helperText,
  fullWidth,
  showError = showErrorOnChange,
  fieldProps,
  ...props
}) => {
  const {
    input: {value, type, onChange, ...restInput},
    meta,
  } = useField(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})

  return (
    <TextInput
      wide
      fullWidth={fullWidth}
      label={label}
      helperText={helperTextOrError}
      error={isError}
      onChange={onChange}
      name={name}
      value={value}
      type={type}
      required={required}
      inputProps={{required, ...restInput}}
      InputProps={{
        endAdornment: (
          <InputAdornment>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}

SearchBoxInput.propTypes = {
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  showError: PropTypes.func,
  fieldProps: PropTypes.object,
}

export default SearchBoxInput
