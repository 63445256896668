import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import backgroundImage from '../../../assets/bg.jpg'
import megabeltLogo from '../../../assets/megabelt.png'

const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    backdropFilter: 'blur(5px)',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '18.75rem',
    marginTop: '6rem',
    zIndex: '1',
    padding: '0.5rem 1rem',
    borderRadius: '0.25rem',
    backgroundColor: theme.palette.common.white,

    '& > :not(:first-child)': {
      marginTop: '1rem',
    },

    '& > form': {
      textAlign: 'center',

      '& > :nth-child(3)': {
        textAlign: 'start',
      },

      '& > button': {
        width: '100%',
      },

      '& > span': {
        display: 'inline-block',
        marginTop: '0.5rem',
        color: theme.palette.error.main,
      },
    },
  },
  logo: {
    margin: 'auto',
    maxWidth: '11rem',
    paddingTop: '2.125rem',
  },
}))

const Login = ({children, ...props}) => {
  const classes = useStyles()

  return (
    <div className={classes.backgroundImage} {...props}>
      <div className={classes.contentWrapper}>
        <div className={classes.card}>
          <img
            className={classes.logo}
            src={megabeltLogo}
            alt="Logo Megabelt"
          />
          {children}
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Login
