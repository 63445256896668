import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import MuiTableCell from '../mui/TableCell'

const useStyles = makeStyles({
  root: {
    fontWeight: 'bold',
    pointerEvents: ({onClick, padding}) =>
      onClick || padding === 'checkbox' ? 'all' : 'none',
  },
})

const TableCellHeader = ({padding, onClick, children, ...props}) => {
  const classes = useStyles({onClick, padding})

  return (
    <MuiTableCell
      variant="head"
      className={classes.root}
      padding={padding}
      onClick={onClick}
      {...props}
    >
      {children}
    </MuiTableCell>
  )
}

TableCellHeader.propTypes = {
  padding: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default TableCellHeader
