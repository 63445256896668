import MenuIcon from '@mui/icons-material/Menu'
import {makeStyles} from '@mui/styles'
import {If} from 'babel-plugin-jsx-control-statements'
import PropTypes from 'prop-types'
import MuiAppBar from '../mui/AppBar'
import IconButton from '../mui/IconButton'
import LinearProgress from '../mui/LinearProgress'
import Toolbar from '../mui/Toolbar'
import AppBarDivider from './AppBarDivider'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.black,
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    margin: '-8px',
    padding: 0,
    marginRight: theme.spacing(4),
    color: theme.palette.common.white,

    [theme.breakpoints.down('sm')]: {
      margin: '0',
      marginRight: theme.spacing(2),
    },
  },
  appBarItems: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',

    '& button': {
      color: theme.palette.common.white,
    },
  },
}))

const AppBar = ({isFetching, onMenuClick, brand, children, ...props}) => {
  const classes = useStyles()

  return (
    <MuiAppBar className={classes.root} {...props}>
      <If condition={isFetching}>
        <LinearProgress />
      </If>
      <Toolbar>
        <IconButton onClick={onMenuClick} className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
        <If condition={brand}>{brand}</If>
        <div className={classes.appBarItems}>{children}</div>
      </Toolbar>
    </MuiAppBar>
  )
}

AppBar.Divider = AppBarDivider

AppBar.propTypes = {
  isFetching: PropTypes.bool,
  brand: PropTypes.node,
  children: PropTypes.node,
  onMenuClick: PropTypes.func.isRequired,
}

export default AppBar
