import RestoreIcon from '@mui/icons-material/Restore'
import SaveIcon from '@mui/icons-material/Save'
import {INVALID_CREDENTIALS_ERROR} from '../../../../../constants/errorCodes'
import * as relations from '../../../../../constants/relations'
import * as resources from '../../../../../constants/resources'
import relation from '../../../../lib/relation'
import {useOneResource, useUpdateResource} from '../../../hooks/api'
import {useAuth, useSession} from '../../../hooks/auth'
import {useAlert} from '../../../hooks/useAlert'
import {clearAllSettings} from '../../../hooks/useLocalSetting'
import Button from '../../containers/Button/Button'
import Widget from '../../containers/Widget/Widget'
import WidgetContainer from '../../containers/Widget/WidgetContainer'
import {ScreenHeader, FormSection} from '../../visual'
import NotFound from '../NotFound'
import ProfileForm from './ProfileForm'
import ProfileSessionsTable from './ProfileSessionsTable'

export const queryDef = ({userId}) => ({
  app: '',
  resource: resources.USERS,
  id: userId,
  expand: relation([relations.ROLE, relations.SESSIONS]),
})

const ProfileEdit = () => {
  const session = useSession()
  const {userId} = session
  const [user, {refetch, isFetching}] = useOneResource(queryDef({userId}))
  const updateUser = useUpdateResource(null, resources.USERS, userId)
  const {refreshAuth} = useAuth()
  const showAlert = useAlert()
  const layoutName = 'profileEditLayout'

  if (!user || user.deletedAt) return <NotFound />

  const handleReset = () => {
    clearAllSettings()
    showAlert('Lokálne nastavenia obnovené', 'success')
  }

  const handleSubmit = async (values) => {
    try {
      await updateUser.mutateAsync(values)
      await refreshAuth()
      showAlert(
        `Používateľ ${userId} uložený, pre pokračovanie sa opäť prihláste`,
        'success',
      )
    } catch (e) {
      if (e.data.errorCode === INVALID_CREDENTIALS_ERROR)
        return {oldPassword: 'Neplatné staré heslo'}
      throw e
    }
  }

  return (
    <ProfileForm onSubmit={handleSubmit} initialValues={user}>
      {({submitting, form, handleSubmit}) => (
        <>
          <ScreenHeader title={`Používateľ ${userId}`}>
            <Button icon={<RestoreIcon />} onClick={handleReset}>
              Výrobné nastavenia
            </Button>
            <Button
              icon={<SaveIcon />}
              variant="contained"
              disabled={submitting}
              onClick={handleSubmit}
            >
              Uložiť
            </Button>
          </ScreenHeader>
          <WidgetContainer name={layoutName} disableDrag disableResize>
            <Widget key="users" data-grid={{w: 4, h: 8, x: 0, y: 0}}>
              <FormSection title="Používateľ">{form}</FormSection>
            </Widget>
            <Widget key="sessionsList" data-grid={{w: 4, h: 12, x: 0, y: 1}}>
              <ProfileSessionsTable
                sessions={user.sessions}
                total={user.sessions.length}
                refetch={refetch}
                isFetching={isFetching}
              />
            </Widget>
          </WidgetContainer>
        </>
      )}
    </ProfileForm>
  )
}

export default ProfileEdit
