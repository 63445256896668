import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import ListItemButton from '../mui/ListItemButton'
import ListItemIcon from '../mui/ListItemIcon'
import ListItemText from '../mui/ListItemText'

const useStyles = makeStyles((theme) => ({
  ListItemIcon: {
    color: `${theme.palette.common.white}`,
  },
  root: {
    color: `${theme.palette.common.white}`,
    textDecoration: 'none',
  },
}))

const NavigationItem = ({children, icon, ...props}) => {
  const classes = useStyles()

  return (
    <ListItemButton className={classes.root} {...props}>
      <ListItemIcon className={classes.ListItemIcon}>{icon}</ListItemIcon>
      <ListItemText>{children}</ListItemText>
    </ListItemButton>
  )
}

NavigationItem.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
}

export default NavigationItem
