import {makeStyles} from '@mui/styles'

const useStyles = makeStyles({
  appBarDivider: {
    flexGrow: 1,
  },
})

const AppBarDivider = ({...props}) => {
  const classes = useStyles()

  return <div className={classes.appBarDivider} {...props} />
}

export default AppBarDivider
