import {makeStyles} from '@mui/styles'
import {If} from 'babel-plugin-jsx-control-statements'
import {isEmpty} from 'lodash'
import PropTypes from 'prop-types'
import {useState} from 'react'
import IconButton from '../mui/IconButton'
import Menu from '../mui/Menu'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    paddingLeft: theme.spacing(1.5),
    margin: theme.spacing(-1),

    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(0.5)} 0`,
    },
  },
}))

const ButtonMenu = ({icon, onClick, children}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)

    if (onClick) {
      onClick(event)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <If condition={!isEmpty(icon)}>
      <IconButton onClick={handleMenu} className={classes.iconButton}>
        {icon}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {children}
      </Menu>
    </If>
  )
}

ButtonMenu.propTypes = {
  icon: PropTypes.node,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default ButtonMenu
