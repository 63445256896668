import CreateIcon from '@mui/icons-material/Create'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import {Link} from '@reach/router'
import {If} from 'babel-plugin-jsx-control-statements'
import {useState} from 'react'
import {ROLE} from '../../../../../constants/relations'
import * as resources from '../../../../../constants/resources'
import {generateResourcePath} from '../../../../../constants/routes'
import relation from '../../../../lib/relation'
import {useDeleteManyResources, useListResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import useLocalSetting from '../../../hooks/useLocalSetting'
import Button from '../../containers/Button/Button'
import DeleteButton from '../../containers/DeleteButton/DeleteButton'
import Filters from '../../containers/Filters/Filters'
import SearchBoxInput from '../../form/SearchBoxInput/SearchBoxInput'
import {ScreenHeader} from '../../visual'
import RolesEdit from '../roles/RolesEdit'
import UsersTable from './UsersTable'

export const queryDef = ({query} = {}) => ({
  app: '',
  resource: resources.USERS,
  query: {
    ...query,
    expand: relation(ROLE),
  },
})

const initialQuery = {
  page: 0,
  pageSize: 10,
  filter: {deleted: false},
}

const queryName = 'usersQuery'

const UsersList = () => {
  const [selectedIds, setSelectedIds] = useState([])
  const [query, setQuery] = useLocalSetting(queryName, initialQuery)
  const {
    data: users,
    total,
    refetch,
    isFetching,
  } = useListResource(queryDef({query}))
  const deleteUsers = useDeleteManyResources(null, resources.USERS)
  const [roleId, setRoleId] = useState(null)
  const showAlert = useAlert()

  const handleDelete = async () => {
    await deleteUsers.mutateAsync(selectedIds)

    setSelectedIds([])
    await refetch()
    showAlert('Vymazané', 'success')
  }

  return (
    <>
      <ScreenHeader title="Používatelia">
        <DeleteButton
          onDelete={handleDelete}
          selectedIds={selectedIds}
          disabled={isFetching}
          data-test-id="deleteButton"
        />
        <Button
          icon={<SupervisorAccountIcon />}
          component={Link}
          to={generateResourcePath('roles')}
        >
          Role
        </Button>
        <Button
          icon={<CreateIcon />}
          variant="contained"
          component={Link}
          to={generateResourcePath('users', 'create')}
        >
          Vytvoriť
        </Button>
      </ScreenHeader>
      <Filters query={query} setQuery={setQuery}>
        <SearchBoxInput
          fullWidth={false}
          name="q"
          label="Vyhľadať používateľa"
        />
      </Filters>
      <UsersTable
        users={users}
        total={total}
        query={query}
        setQuery={setQuery}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        setRoleId={setRoleId}
      />
      <If condition={roleId}>
        <RolesEdit roleId={roleId} setRoleId={setRoleId} refetch={refetch} />
      </If>
    </>
  )
}

export default UsersList
