import MuiList from '@mui/material/List'
import PropTypes from 'prop-types'

export const override = {}

const List = ({children, ...props}) => <MuiList {...props}>{children}</MuiList>

List.propTypes = {
  children: PropTypes.node.isRequired,
}

export default List
