import PropTypes from 'prop-types'
import ControlledDatagrid from '../../containers/Datagrid/ControlledDatagrid'
import dateCell from '../../containers/Datagrid/cells/dateCell'
import imageCell from '../../containers/Datagrid/cells/imageCell'

const columns = [
  {accessor: 'file.filename', Header: 'Názov súboru'},
  {
    accessor: 'createdAt',
    Header: 'Vytvorené',
    Cell: dateCell('createdAt'),
  },
  {accessor: 'file.mimeType', Header: 'Typ súboru', disableSortBy: true},
  {accessor: 'id', Header: 'Obrázok', Cell: imageCell()},
]

const MediaLibraryTable = ({
  images,
  total,
  query,
  setQuery,
  selectedIds,
  setSelectedIds,
  ...props
}) => {
  return (
    <ControlledDatagrid
      data={images}
      columns={columns}
      total={total}
      query={query}
      setQuery={setQuery}
      currentpage={query?.page}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      {...props}
    />
  )
}

MediaLibraryTable.propTypes = {
  images: PropTypes.array.isRequired,
  total: PropTypes.number,
  query: PropTypes.object,
  setQuery: PropTypes.func,
  selectedIds: PropTypes.array,
  setSelectedIds: PropTypes.func,
}

export default MediaLibraryTable
