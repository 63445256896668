import {isRequired, withForm} from 'common-fe/forms'
import * as resources from '../../../../../constants/resources'
import * as baseSchemas from '../../../../common/baseSchemas'
import * as schemas from '../../../../common/schemas'
import * as enums from '../../../constants/enums'
import {useAsyncUniqueValidate} from '../../../hooks/useAsyncUniqueValidate'
import EnumInput from '../../form/EnumInput/EnumInput'
import TextInput from '../../form/TextInput/TextInput'

const RolesForm = () => {
  const validateName = useAsyncUniqueValidate({
    app: null,
    resource: resources.ROLES,
    column: 'name',
  })
  return (
    <>
      <TextInput
        name="name"
        label="Meno"
        required={isRequired(schemas.role.name)}
        fieldProps={{validate: validateName}}
      />
      <EnumInput
        name="permissions"
        label="Oprávnenia"
        enumName={enums.PERMISSIONS}
        multiple
        required={isRequired(schemas.role.permissions)}
      />
    </>
  )
}

const schema = baseSchemas.object(schemas.role)

export default withForm({schema})(RolesForm)
