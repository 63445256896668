import {For} from 'babel-plugin-jsx-control-statements'
import {includes, isFunction} from 'lodash'
import PropTypes from 'prop-types'
import {TableCell, TableRow} from '../../visual'

const DatagridRow = ({
  row,
  selectedIds,
  rowStyles,
  columnProps,
  prepareRow,
  onClick,
  ...props
}) => {
  prepareRow(row)
  const isSelected = includes(selectedIds, row.values.id)
  const customRowStyles = isFunction(rowStyles) ? rowStyles(row) : rowStyles

  const handleRowClick = (e) => onClick(row, e)

  return (
    <TableRow
      selected={isSelected}
      key={row.id}
      onClick={onClick && handleRowClick}
      customRowStyles={customRowStyles}
      {...row.getRowProps()}
      {...props}
    >
      <For each="cell" of={row.cells} index="idx">
        <TableCell
          padding={cell.column.checkbox ? 'checkbox' : 'default'}
          {...cell.getCellProps()}
        >
          {cell.render('Cell', columnProps)}
        </TableCell>
      </For>
    </TableRow>
  )
}

DatagridRow.propTypes = {
  row: PropTypes.object.isRequired,
  rowStyles: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  columnProps: PropTypes.object,
  prepareRow: PropTypes.func.isRequired,
  onClick: PropTypes.func,
}

export default DatagridRow
