import MuiFormControl from '@mui/material/FormControl'
import PropTypes from 'prop-types'

export const override = {}

const FormControl = ({children, ...props}) => (
  <MuiFormControl variant="outlined" {...props}>
    {children}
  </MuiFormControl>
)

FormControl.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FormControl
