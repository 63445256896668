import {useState, useEffect} from 'react'

const useDebouncedValue = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(null)

  useEffect(() => {
    if (value === debouncedValue) return
    const handler = window.setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      window.clearTimeout(handler)
    }
  }, [value, delay, debouncedValue])

  return debouncedValue
}

export default useDebouncedValue
