import {omitBy, pickBy, isUndefined, mapValues, isEmpty} from 'lodash'
import * as routes from '../../../constants/routes'
import generatePath from '../../lib/generatePath'
import {SERVER_UNAVAILABLE_ERROR} from '../constants/frontendErrorCodes'

export const getQueryString = (query) => {
  const searchParams = omitBy(mapValues(query, JSON.stringify), isUndefined)
  return !isEmpty(searchParams)
    ? `?${new window.URLSearchParams(searchParams).toString()}`
    : ''
}

export const api = async (method, pathPattern, options) => {
  const {data, query, params, sessionToken, asMultipart} = options || {}
  const path = generatePath(pathPattern, params)
  const search = getQueryString(query)
  const hasBody = !['HEAD', 'GET'].includes(method.toUpperCase())
  const headers = pickBy({
    'Content-type': hasBody && !asMultipart ? 'application/json' : undefined,
    Authorization: sessionToken ? `Bearer ${sessionToken}` : undefined,
  })
  const body = hasBody
    ? asMultipart
      ? data
      : JSON.stringify(data || {})
    : undefined
  return window
    .fetch(routes.API + path + search, {
      method,
      headers,
      body,
    })
    .catch(() => {
      const error = new Error('Server unavailable')
      error.data = {errorCode: SERVER_UNAVAILABLE_ERROR}
      throw error
    })
    .then((res) => {
      const contentType = res.headers.get('content-type') || ''
      if (contentType.indexOf('application/json') > -1) {
        return res.json().then((data) => ({res, data}))
      } else {
        return res.text().then((data) => ({res, data}))
      }
    })
    .then(({res, data}) => {
      if (res.ok) {
        const contentRange = res.headers.get('content-range')
        const total =
          contentRange && parseInt(contentRange.split('/').pop(), 10)
        if (method === 'GET' && contentRange && data) return {data, total}
        return data
      } else {
        const error = new Error(
          (data && data.error) || res.statusText || 'Unknown error',
        )
        error.data = data
        throw error
      }
    })
}
