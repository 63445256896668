import * as manufactureStatuses from '../../../../../constants/megaseal/megasealManufactureStatuses'

export default {
  [manufactureStatuses.NEW]: 'Nový',
  [manufactureStatuses.MANUFACTURING]: 'Vo výrobe',
  [manufactureStatuses.LABELING]: 'Na značení',
  [manufactureStatuses.INSPECTING]: 'Na kontrole',
  [manufactureStatuses.TRANSFERRING]: 'V prevode',
  [manufactureStatuses.DONE]: 'Dokončené',
}
