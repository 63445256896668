import MuiTableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'

export const override = {
  hover: {
    cursor: 'pointer',
  },
}

const TableRow = ({hover, selected, children, ...props}) => (
  <MuiTableRow hover={hover} selected={selected} {...props}>
    {children}
  </MuiTableRow>
)

TableRow.propTypes = {
  hover: PropTypes.bool,
  selected: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default TableRow
