import S from 'fluent-json-schema'
import * as contentTypes from '../../../../constants/contentTypes'
import * as baseSchemas from '../../baseSchemas'

export const valueSchemas = {
  content: S.string(),
  direction: S.string().enum(['row', 'col']).required(),
  imageId: baseSchemas.uuid().required(),
  alt: baseSchemas.standardString().required(),
  title: baseSchemas.standardString().required(),
  downloadId: baseSchemas.uuid().required(),
  link: baseSchemas.standardString().required(),
}

export const CONTENT_FLEX = {
  type: contentTypes.FLEX,
  validChildren: [
    contentTypes.TABS,
    contentTypes.RICH_TEXT,
    contentTypes.IMAGE,
    contentTypes.FLEX,
    contentTypes.FILE,
    contentTypes.BANNER,
  ],
  value: baseSchemas.object({
    direction: valueSchemas.direction,
  }),
}

export const CONTENT_RICH_TEXT = {
  type: contentTypes.RICH_TEXT,
  validChildren: null,
  value: baseSchemas.object({
    content: valueSchemas.content,
  }),
}

export const CONTENT_TABS = {
  type: contentTypes.TABS,
  validChildren: [contentTypes.TAB],
}

export const CONTENT_TAB = {
  type: contentTypes.TAB,
  validChildren: [
    contentTypes.RICH_TEXT,
    contentTypes.IMAGE,
    contentTypes.FILE,
  ],
  value: baseSchemas.object({
    title: valueSchemas.title,
  }),
}

export const CONTENT_IMAGE = {
  type: contentTypes.IMAGE,
  validChildren: null,
  value: baseSchemas.object({
    imageId: valueSchemas.imageId,
    alt: valueSchemas.alt,
  }),
}

export const CONTENT_FILE = {
  type: contentTypes.FILE,
  validChildren: null,
  value: baseSchemas.object({
    downloadId: valueSchemas.downloadId,
    title: valueSchemas.title,
  }),
}

export const CONTENT_BANNER = {
  type: contentTypes.BANNER,
  validChildren: [contentTypes.IMAGE],
  value: baseSchemas.object({
    title: valueSchemas.title,
    link: valueSchemas.link,
  }),
}

export const ALL_CONTENT = [
  CONTENT_FLEX,
  CONTENT_RICH_TEXT,
  CONTENT_TABS,
  CONTENT_TAB,
  CONTENT_IMAGE,
  CONTENT_FILE,
  CONTENT_BANNER,
]
