/**
 * Values in `manufacture.status` column:
 */

export const NEW = 'NEW'
export const MANUFACTURING = 'MANUFACTURING'
export const LABELING = 'LABELING'
export const INSPECTING = 'INSPECTING'
export const TRANSFERRING = 'TRANSFERRING'
export const DONE = 'DONE'

export const ALL_MANUFACTURE_STATUSES = [
  NEW,
  MANUFACTURING,
  LABELING,
  INSPECTING,
  TRANSFERRING,
  DONE,
]
