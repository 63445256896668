import MuiListItemIcon from '@mui/material/ListItemIcon'
import PropTypes from 'prop-types'

export const override = {}

const ListItemIcon = ({children, ...props}) => (
  <MuiListItemIcon {...props}>{children}</MuiListItemIcon>
)

ListItemIcon.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ListItemIcon
