import CircularProgress from '@mui/material/CircularProgress'
import {Choose, When, Otherwise} from 'lodash'
import PropTypes from 'prop-types'
import Button from '../mui/Button'

const BaseButton = ({
  children,
  color,
  disabled,
  isPending,
  variant,
  onClick,
  icon,
  ...props
}) => {
  return (
    <Button
      disabled={isPending || disabled}
      onClick={onClick}
      variant={variant}
      color={color}
      {...props}
      startIcon={
        <Choose>
          <When condition={isPending}>
            <CircularProgress size={20} />
          </When>
          <Otherwise>{icon}</Otherwise>
        </Choose>
      }
    >
      {children}
    </Button>
  )
}

BaseButton.propTypes = {
  disabled: PropTypes.bool,
  isPending: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
}

export default BaseButton
