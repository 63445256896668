import MuiButton from '@mui/material/Button'

export const override = {
  // Empty
}

const Button = ({...props}) => (
  <MuiButton color="primary" variant="outlined" {...props} />
)

Button.propTypes = {
  // Empty
}

export default Button
