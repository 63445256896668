import * as permissions from '../../../constants/permissions'

export default {
  [permissions.MANAGE_USERS]: 'Spravovať používateľov',
  [permissions.MANAGE_SYSTEM_SETTINGS]: 'Spravovať systémové nastavenia',
  [permissions.MANAGE_CLIENTS]: 'Spravovať odoberateľov',
  [permissions.MANAGE_SEALS]: 'Spravovať profily',
  [permissions.MANAGE_CALCULATIONS]: 'Spravovať kalkulácie',
  [permissions.MANAGE_MANUFACTURES]: 'Spravovať výrobné listy',
  [permissions.MANAGE_MATERIALS]: 'Spravovať materiály',
  [permissions.MANAGE_BLANKS]: 'Spravovať polotovary',
  [permissions.MANAGE_ACCESSORIES]: 'Spravovať príslušenstvo',
  [permissions.MANAGE_EXPEDITIONS]: 'Spravovať expedície',
  [permissions.MANAGE_ORDERS]: 'Spravovať objednávky',
  [permissions.MANAGE_PRODUCTS]: 'Spravovať produkty',
}
