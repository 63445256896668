import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
})

const ContentContainer = ({children, ...props}) => {
  const classes = useStyles()

  return (
    <div className={classes.root} {...props}>
      {children}
    </div>
  )
}

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ContentContainer
