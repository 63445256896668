import MuiInput from '@mui/material/Input'
import PropTypes from 'prop-types'

export const override = {}

const Input = ({multiple, ...props}) => (
  <MuiInput multiple={multiple} {...props} />
)

Input.propTypes = {
  multiple: PropTypes.bool,
}

export default Input
