import MuiTooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import {useRef} from 'react'

export const override = {
  // Empty
}

const Tooltip = ({children, ...props}) => {
  const ref = useRef()
  return (
    <MuiTooltip {...props} ref={ref}>
      {children}
    </MuiTooltip>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Tooltip
