import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {showErrorOnChange} from 'common-fe/forms'
import PropTypes from 'prop-types'
import {useState} from 'react'
import {useField} from 'react-final-form'
import {IconButton, InputAdornment, TextInput} from '../../visual'

const PasswordInput = ({
  readOnly,
  name,
  label,
  required,
  helperText,
  fullWidth,
  showError = showErrorOnChange,
  fieldProps,
  ...props
}) => {
  const {
    input: {value, onChange, ...restInput},
    meta,
  } = useField(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})
  const [showPassword, setShowPassword] = useState(false)

  const handleShowPassword = () => setShowPassword((prevState) => !prevState)

  const Icon = showPassword ? Visibility : VisibilityOff

  if (readOnly) return null // Passwords can only be changed, not read
  return (
    <TextInput
      fullWidth={fullWidth}
      label={label}
      helperText={helperTextOrError}
      error={isError}
      onChange={onChange}
      name={name}
      value={value}
      type={showPassword ? 'text' : 'password'}
      required={required}
      inputProps={{required, ...restInput}}
      InputProps={{
        endAdornment: (
          <InputAdornment>
            <IconButton onClick={handleShowPassword}>
              <Icon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}

PasswordInput.defaultProps = {
  fullWidth: true,
}

PasswordInput.propTypes = {
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  showError: PropTypes.func,
  fieldProps: PropTypes.object,
}

export default PasswordInput
