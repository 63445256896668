import MuiTypography from '@mui/material/Typography'
import PropTypes from 'prop-types'

export const override = {}

const Typography = ({children, ...props}) => (
  <MuiTypography {...props}>{children}</MuiTypography>
)

Typography.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Typography
